/**
 * *Rôle : Liste les catégories des videos
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import H2 from '../functional/H2';
import { Link } from 'gatsby';
import Loader from '../functional/Loader';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { categoryVideo } from '../../redux/categoryVideo/actionCategoryVideo';

const ListeCategoryVideos = () => {
	// REDUX STORE
	const dispatch = useDispatch();
	const categoryVideoInRedux = useSelector((state) => state.categoryVideo);
	const [allCategory, setAllCategory] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (categoryVideoInRedux) {
			setAllCategory(categoryVideoInRedux.state);
		}
		if (!categoryVideoInRedux) {
			setLoading(true);
			axios
				.get(
					`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAllFolders?per_page=100&page=1`
				)
				.then(function (res) {
					setAllCategory(res.data);
					// Dispatch in Redux
					dispatch(categoryVideo(res.data));
					setLoading(false);
				})
				.catch(function (err) {
					// console.log('error get FOLDERS', err)
					setLoading(false);
				});
		}
	}, [categoryVideoInRedux, dispatch]);

	const createUri = (defaultUri) => {
		const uri = defaultUri.replace(`/users/${process.env.GATSBY_VIMEO_USER_ID}/projects/`, '');
		return uri;
	};

	let listeCategory;
	if (allCategory) {
		listeCategory = allCategory.data.map((cat, index) =>
			cat.metadata.connections.videos.total > 0 && cat.name !== 'PRIVE' ? (
				cat.metadata.connections.parent_folder &&
				cat.metadata.connections.parent_folder.uri ===
					'/users/136301955/projects/5799174' ? null : (
					<Link key={index} to={`/watch-video/category/${createUri(cat.uri)}`}>
						<div className="flex items-start justify-between my-2">
							<button className="w-9/12 block hover:text-primary hover:underline animate text-left uppercase">
								{cat.name}
							</button>
							<div className="flex justify-end w-3/12 ml-4">
								<span
									style={{ fontSize: '0.55rem' }}
									className="bg-secondary rounded-full text-white flex items-center justify-center w-7 h-7"
								>
									{cat.metadata.connections.videos.total}
								</span>
							</div>
						</div>
					</Link>
				)
			) : (
				<div key={index}></div>
			)
		);
	}

	return (
		<div>
			<div className="bg-gray-100 p-10 mb-5 mt-5 lg:mt-0">
				<H2>Catégories</H2>

				<div>
					{!loading ? (
						listeCategory
					) : (
						<div className="flex items-center justify-center py-5">
							<Loader />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ListeCategoryVideos;
