/**
 *  Rôle : Page d'accueil des Vidéos
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import StickyBox from 'react-sticky-box';
import Container from '../components/body/Container';
import Layout from '../components/body/layout';
import Seo from '../components/seo';
import ContainerWithSideBarre from '../components/body/ContainerWithSideBarre';
import ContentLeft from '../components/body/ContentLeft';
import ContentRight from '../components/body/ContentRight';
import SideBar from '../components/marketing/sideBar/SideBar';
import ListeLastVideos from '../components/content/ListeLastVideos';
import ListeCategory from '../components/content/ListeCategoriesVideos';

const Videos = () => {
	return (
		<Layout title="Vidéos">
			<Seo title="Vidéos Nader Abou Anas" />
			<Container>
				<ContainerWithSideBarre>
					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-8/12 stickyBox"
					>
						<ContentLeft backgroundDisplay={false}>
							<ListeLastVideos />
						</ContentLeft>
					</StickyBox>

					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-4/12 stickyBox"
					>
						<ContentRight>
							<StickyBox offsetTop={20} offsetBottom={20} className="stickyBox">
								<ListeCategory />
								<SideBar modCategoriesDisplay={false} />
							</StickyBox>
						</ContentRight>
					</StickyBox>
				</ContainerWithSideBarre>
			</Container>
		</Layout>
	);
};

export default Videos;
